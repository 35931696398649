import {
  landFilter,
  landIssueFilter,
  landPreserveFilter,
  monitoringDueFilter,
  teamFilter
} from '../utils/parcel';
export default {
  computed: {
    mapConfig() {
      return this.$store.state.parcel.mapConfig;
    },

    parcelsComputed() {
      let parcels = [...(this.$store.getters.parcels || [])];
      const type = this.$store.state?.loggedInAs?.type;

      if (type === 'user') {
        return parcels;
      }

      if (this.$store?.getters?.parcelSetting?.team !== false) {
        parcels = teamFilter(parcels, this.mapConfig.team);
      }
      parcels = landFilter(parcels, this.mapConfig.land);

      if (
        this.$store.state.loggedInAs.type === 'organization' &&
        this.$store.state.landPreserves?.length &&
        this.mapConfig.showPreserve === 'selected'
      ) {
        parcels = landPreserveFilter(parcels, this.mapConfig.landPreserve);
      }

      const monitoringDue = this.mapConfig.monitoringDue;
      const monitoringDueAny = this.mapConfig.monitoringDueAny;
      const monitoringDueMissing = this.mapConfig.monitoringDueMissing;

      parcels = monitoringDueFilter({
        parcels,
        monitoringDue,
        monitoringDueAny,
        monitoringDueMissing
      });

      parcels = landIssueFilter(parcels, this.mapConfig.landIssue);

      if (this.mapConfig.sort === 'recentUpdated') {
        parcels = parcels.sort((a, b) => {
          const recentDate = parcel => {
            try {
              return (parcel.recent_activity.createdAt || parcel.updatedAt).toDate();
            } catch {
              return 0;
            }
          };

          try {
            const dateA = recentDate(a);
            const dateB = recentDate(b);
            return dateB - dateA;
          } catch (error) {
            console.error(error);
            return 0;
          }
        });
      }

      return parcels;
    }
  }
};
